import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { VideoService } from "./video.service";
import { ToastrService } from "ngx-toastr";
import { DomSanitizer } from "@angular/platform-browser";
import { ClipboardModule } from '@angular/cdk/clipboard'
import { catchError, map, startWith } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
export interface Tags {
  name: string;
}

@Component({
  selector: "app-video-editing-model",
  templateUrl: "./video-editing-model.component.html",
  styleUrls: ["./video-editing-model.component.css"],
})
export class VideoEditingModelComponent implements OnInit {
  @ViewChild('videoIdInput') videoIdInput: ElementRef;
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  folderIndex:any=0;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  posterUrl: string;
  pdfURL: string;
  teacherNoteUrl: string;
  videoUrl:string;
  langPdfURL: string;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  Tagging: Tags[] = [{ name: "Demo" }];
  classData: any;
  CourseData: string[] = ["Academic Course", "Extra-Curricular", "Free-Videos"];
  subjectData: any;
  chapterData: any;
  uploadedVideoUrl:any=null;
  uploadedTeacherNoteUrl:any=null;
  selectVideoType:any=null;
  typesOfList: string[] = [
    "Playlist-1",
    "Playlist-2",
    "Playlist-3",
    "Playlist-4",
    "Playlist-5",
  ];
  description:any;
  rootBoardFolders:any;
  playlistFalg: boolean = false;
  linkPage: boolean = false;
  productLines: any;
  videoSrc: any;
  showVideoFlag: boolean;
  videoLinks: any = [];
  languageList: any;
  idProduct: any;
  idSyllabus :any;
  idState :any;
  productData: any;
  prodFlag: boolean;
  subFlag: boolean;
  videoUpdateFlag: any;
  academicUpdateVideoData: any;
  idOfflineVideoCourse: any;
  syllabusData: any;
  stateData:any;
  syllabusFlag :any;
  classFlag: boolean;
  generatedValue:string;
  generatedValueFlag:boolean = false;
  insertValue: any=[];
  insertValueFlag :boolean=false;
  progress:number;
  //subFolder:boolean=false;
  selectedSubFolderName:any=String;
  selectedFolderId:any;
  parentFolder:any='Folders';
  selectedBoardName:any;
  selectedGradeName:any;
  file:any;
  formData:any;
  isVideoIdChanged:boolean=false;
  isVideoFileChanged:boolean=false;
  radioOptions = [
    { value: 'id', label: 'Video ID' },
    { value: 'file', label: 'File Upload' },
  ]
  modules: { toolbar: (string[] | { header: number; }[] | { list: string; }[] | { script: string; }[] | { indent: string; }[] | { header: (number | boolean)[]; }[] | ({ color: any[]; background?: undefined; } | { background: any[]; color?: undefined; })[] | { font: any[]; }[] | { align: any[]; }[])[]; };

  @ViewChild('pdfFile')
  pdfFile: ElementRef;
  gradeFolders:any;
  subjectFolders:any
  teachNoteFileType:String;
  filteredOptions: Observable<Headings[]>;
  headings: any = [];
  videoEnLink: string=null;
  constructor(
    private dialog: MatDialog,
    private _formBuilder: FormBuilder,
    public toaster: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private serv: VideoService,
    private dialogRef: MatDialogRef<VideoEditingModelComponent>,
    private sanitizer: DomSanitizer,
    private http: HttpClient
  ) {
    if (this.data.offData != null) {
      this.videoUpdateFlag = true;

      if (this.data.linkUpload) {
        this.linkPage = true;
      }
      
      this.academicUpdateVideoData = this.data.offData;
      console.log("data",data);
      console.log(this.academicUpdateVideoData);
      if(this.academicUpdateVideoData.videoEnLink){
        setTimeout(()=>{
          this.selectedOption('id')
        },1000)
      }
  
      this.firstFormGroup = this._formBuilder.group({
        topic: [this.academicUpdateVideoData.topic, [Validators.required,Validators.maxLength(40)]],
        productLine: ["5"],
        idSyllabus:[this.academicUpdateVideoData.idSyllabus, Validators.required],
        idState:[this.academicUpdateVideoData.idState, Validators.required],
        classStandard:[this.academicUpdateVideoData.idClassStandard, Validators.required],
        subject:[this.academicUpdateVideoData.idSubject, Validators.required],
        chapter:[this.academicUpdateVideoData.idSubjectChapter, Validators.required],
        videoSeqNum: [
          this.academicUpdateVideoData.videoSeqNumber,
          Validators.required,
        ],
        description: [this.academicUpdateVideoData.description],
        language: [""],
      });
      
      let checkValue = "";
      if(this.academicUpdateVideoData.videoEnLink){
        checkValue = "id";
      }

      this.secondFormGroup = this._formBuilder.group({
        folder:[""],
        heading:[this.academicUpdateVideoData.heading, Validators.required],
        question:[this.academicUpdateVideoData.question, Validators.required],
        answer:[this.academicUpdateVideoData.answer, Validators.required],
        pdfURL:[""],
        videoUrl:[""],
        videoLinkId: [this.academicUpdateVideoData.videoEnLink],
        video:Validators.required,
        selectVideoType:[checkValue],
        videoEnLink:[this.academicUpdateVideoData.videoEnLink],
        teacherNoteUrl:[""]
        
      });
      // this.secondFormGroup.controls.videoEnLink = this.academicUpdateVideoData.videoEnLink
      this.pdfURL=this.academicUpdateVideoData.pdfUrl;
      this.teacherNoteUrl = this.academicUpdateVideoData.teacherNoteUrl;
      console.log('test', this.teacherNoteUrl)
      this.checkTeacherNote()

      // console.log('dasadaddadadad', this.academicUpdateVideoData)

      this.thirdFormGroup = this._formBuilder.group({
        language: [null, Validators.required],
        folder:[null, Validators.required],
        heading:[null, Validators.required],
        question:[null, Validators.required],
        answer:[null, Validators.required],
        insertText: [null],
        langPdf :[null]
      });

      this.idSyllabus =this.academicUpdateVideoData.idSyllabus;
      this.idState = this.academicUpdateVideoData.idState;
      this.classFlag=true;

      // debugger 
      this.idProduct =  this.academicUpdateVideoData.idProduct;
      this.videoLinks = this.academicUpdateVideoData.videoLinks;
      this.getAllSubjects(this.academicUpdateVideoData.idClassStandard);
      this.getSubjects(this.academicUpdateVideoData.idSubject);
      this.getAllSyllabus();
      this.getAllState();
      this.getAllproductLines();
      this.getAllClassStandard();
      this.getAllLanguage();
      this.getSubjects(this.academicUpdateVideoData.idSubject);

      
      this.idOfflineVideoCourse = this.data.idOfflineVideoCourse;
     this.getHeadings(this.academicUpdateVideoData.idSubjectChapter);
     
    } 
    
    else {
      if (this.data.linkUpload) {
        this.linkPage = true;
      }


      this.getAllproductLines();
      this.getAllClassStandard();
      this.getAllLanguage();
      this.getAllSyllabus();
      this.getAllState();
      this.updateFormValues();

    }
  }
 
    // Call a method to update the form values here
    checkTeacherNote () {
      if (this.teacherNoteUrl) {
        // List of possible document and video extensions
        const documentExtensions = ['pdf', 'doc', 'docx', 'ppt', 'pptx'];
        const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv','webm'];
    
        // Extract the file extension from the URL
        const fileExtension = this.teacherNoteUrl.split('.').pop().toLowerCase();
    
        // Check the extension and assign to the corresponding variable
        if (documentExtensions.includes(fileExtension)) {
            this.teachNoteFileType = 'document';
        } else if (videoExtensions.includes(fileExtension)) {
            this.teachNoteFileType = 'video';
        } else {
            console.warn('File type is not recognized as document or video.');
        }
    }
    }

    openDialog(): void {
      this.dialog.open(VideoEditingModelComponent, {
        width: '600px',
        data: {
          // Pass any necessary data here
          someData: 'Your data here'
        },
      });
    }
    onVideoIdChange() {
      this.isVideoIdChanged = true;
      // Update validation manually (optional)
      this.secondFormGroup.updateValueAndValidity();
    }
    
    onVideoFileChange() {
      this.isVideoFileChanged = true;
      // Update validation manually (optional)
      // this.secondFormGroup.updateValueAndValidity();
    }

    shouldFolderBeRequired(): boolean {
      return this.isVideoIdChanged || this.isVideoFileChanged;
    }

  updateFormValues() {
    this.firstFormGroup = this._formBuilder.group({
      topic: ["", Validators.required],
      productLine: ["5"],
      idState:["",Validators.required],
      idSyllabus: ["", Validators.required],
      classStandard: ["", Validators.required],
      subject: ["", Validators.required],
      chapter: ["", Validators.required],
      videoSeqNum: ["",Validators.required],
      description: [null],
      
    });

    this.secondFormGroup = this._formBuilder.group({
      folder: [""],//["", Validators.required],
      heading: ["", [Validators.required, Validators.maxLength(100)]],
      question: ["", [Validators.required,Validators.maxLength(500)]],
      answer: ["", [Validators.required, Validators.maxLength(1500)]],   
      pdfURL:[""] ,
      videoUrl:[""] ,
      videoEnLink:[""],
      selectVideoType:[""],
      teacherNoteUrl:[""]
      
    });

    this.thirdFormGroup = this._formBuilder.group({
      language: ["", Validators.required],
      folder: ["", Validators.required],
      heading: ["", Validators.required],
      question: ["", Validators.required],
      answer: ["", Validators.required],  
      insertText: [""],
      langPdf:[""]

    });
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || "").trim()) {
      this.Tagging.push({ name: value.trim() });
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }
  }

  remove(fruit: Tags): void {
    const index = this.Tagging.indexOf(fruit);

    if (index >= 0) {
      this.Tagging.splice(index, 1);
    }
  }

  ngOnInit() {
    this.getFolders('root')
    this.filteredOptions = this.secondFormGroup.controls["heading"].valueChanges
    .pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this._filter(name) : this.headings.slice())
    );
    console.log('this.shouldFolderBeRequired()', this.shouldFolderBeRequired())
    if (this.shouldFolderBeRequired()) {
      // Add the 'required' validator back if needed
      this.secondFormGroup.controls['folder'].setValidators([Validators.required]);
    } else {
      // Remove the 'required' validator
      this.secondFormGroup.controls['folder'].clearValidators();
    }
  }



  selectedOption (vl) {
    console.log('vl', vl)
    this.selectVideoType = vl
    if (vl === 'id') {setTimeout(() => {
      this.videoIdInput.nativeElement.focus();
    }, 100);
    }
  }
  getSubjects(event) {
    if (event != null && event != "") {
      this.chapterData = null;
      this.getProduct(
        5,
        this.firstFormGroup.controls["classStandard"].value,
        event,
        this.firstFormGroup.controls["idSyllabus"].value,
        this.firstFormGroup.controls["idState"].value
      );

      this.serv.getallChapter(event,  this.firstFormGroup.controls["classStandard"].value, this.firstFormGroup.controls["idSyllabus"].value,this.firstFormGroup.controls["idState"].value).subscribe(
        (res: any) => {
          if (res.data != null && res.statusCode == "200") {
            this.playlistFalg = true;
            this.chapterData = res.data;
          }
        },
        (error) => {
          this.toaster.error(error.error.message, "An Error Occured!");
        }
      );
    }
  }

  getAllproductLines() {
    this.serv.getallProductLines("OFFLINE_VIDEO").subscribe((res) => {
      if (res != null) {
        this.productLines = res;
      }
    });
  }

  getAllClassStandard() {
    this.serv.getClassStandard().subscribe((res: any = []) => {
      if (res.data != null) {
        this.classData = res.data;

        for (let i = 0;i<this.classData.length;i++) 
        {
          if (this.classData[i].idClassStandard == 4)
          {
            this.classData.splice(i,1);
          }
        }
      }
    });
  }

  getAllSyllabus() {
    this.serv.getAllSyllabus().subscribe((res: any = []) => {
      if (res.data != null) {
        this.syllabusData = res.data;

        for (let i = 0; i < this.syllabusData.length; i++) {
          if (this.syllabusData[i].syllabusName === "NA") {
            this.syllabusData.splice(i, 1);
          }
        }
      }
    });
  }
  getAllState() {
    this.serv.getAllState().subscribe((res: any = []) => {
      if (res.data != null) {
        this.stateData = res.data;
        for (let i = 0; i < this.stateData.length; i++) {
          if (this.stateData[i].state === "NA") {
            this.stateData.splice(i, 1);
          }
        }
      }
    });
  }

  getAllSubjects(event) {
    this.subjectData = [];
    this.chapterData =null;
    this.subFlag = false;
    let idSyb = this.firstFormGroup.controls["idSyllabus"].value;
    let idState = this.firstFormGroup.controls["idState"].value;
    if (idSyb == null)
    {
      this.toaster.error("Please select any syllabus.");
      return 
    }

      if (idState == null)
    {
      this.toaster.error("Please select any state.");
      return 
    }
    if (event != null) {
      this.serv.getAcademicSubjects(event,idSyb,idState).subscribe(
        (res: any = []) => {
          if (res.data != null) {
            this.subjectData = res.data;
            this.subFlag = true;
            if (!this.videoUpdateFlag)  this.firstFormGroup.controls["subject"].setValue(-1);
          }
        },
        (error) => {
          this.toaster.error(error.error.message, "An Error Occured!");
        }
      );
    }
  }
getFolders(folder) {
  console.log('folder', folder,)
  let folderId,i
  
    if (folder === 'root')
      {
        folderId = 'root'
        this.folderIndex=0
      }else if(folder.parent===null) {
        folderId = folder.id
        this.folderIndex=1
      } else {
        folderId = folder.id
        this.folderIndex=2
      }
    console.log('folderIndex', this.folderIndex)

   
  this.http.get('api/v1/offlineCourse/get-vdocipher-folders?folderId='+folderId).subscribe(
    (res:any)=> {
      if(this.folderIndex===0){
        //this.rootBoardFolders = res.data.folderList
        this.rootBoardFolders = res.data.folderList.filter(item => item.name !== 'Miscellaneous' && item.name !== 'Building_Legends');
        console.log('dsadad',this.rootBoardFolders)
        this.isVideoFileChanged = false
      }else if (this.folderIndex===1){
        this.selectedBoardName = folder.name
        this.gradeFolders = res.data.folderList
        console.log('gradeFolders',this.gradeFolders)
      }else if(this.folderIndex===2){
        this.subjectFolders = null;
        this.subjectFolders = res.data.folderList
        this.selectedGradeName =folder.name
        console.log('subjectFolders',this.subjectFolders)

      }

    }
  )
}
getSubFolders(selectedFolder){
  console.log('video id', selectedFolder)
  if(selectedFolder.foldersCount>0)
    {
      this.getFolders(selectedFolder)
      this.selectedSubFolderName = selectedFolder.name
     
    }

}
  createVideo() {
  console.log(this.isVideoFileChanged, this.secondFormGroup.controls["folder"].value.id)
  if(this.isVideoFileChanged && this.secondFormGroup.controls["folder"].value.id==null && this.secondFormGroup.controls["folder"].value.id==undefined){
    this.secondFormGroup.get('folder')?.setValidators([Validators.required]);
    this.secondFormGroup.get('folder')?.updateValueAndValidity();
    this.toaster.error('Please select Folder on video info.');
    return;
  }

    if (this.uploadedVideoUrl == null && this.secondFormGroup.controls.videoEnLink.value == null) {
      this.toaster.error('Please provide either a video URL or a video ID.');
      return; // Exit the function if neither URL is provided
    }
    if(this.uploadedVideoUrl === ''){
      this.toaster.error('Please choose a video to upload')
    }else{
      if (this.videoUpdateFlag) {
        if (this.firstFormGroup.valid && this.secondFormGroup.valid) {
          let request = {
            idProduct: this.idProduct,
            topic: this.firstFormGroup.controls["topic"].value,
            description: this.firstFormGroup.controls["description"].value,
            idProductGroup: -1,
            folderId: (this.secondFormGroup.controls["folder"].value).id,
            idSubjectChapter: this.firstFormGroup.controls["chapter"].value,
            idSubject: this.firstFormGroup.controls["subject"].value,
            videoSeqNumber: this.firstFormGroup.controls["videoSeqNum"].value,
            idClassStandard: this.firstFormGroup.controls["classStandard"].value,
            videoLinks: this.videoLinks,
            heading: this.secondFormGroup.controls["heading"].value,
            question: this.secondFormGroup.controls["question"].value,
            answer: this.secondFormGroup.controls["answer"].value,
            pdfUrl:this.pdfURL,
            language: this.thirdFormGroup.controls["language"].value,
            videoEnLink: this.secondFormGroup.controls.videoEnLink.value,
            videoUrl:this.uploadedVideoUrl,
            teacherNoteUrl: this.teacherNoteUrl
          };
  
            console.log("object",request, this.secondFormGroup.controls.videoEnLink.value);
          this.serv.updateVideo(request, this.idOfflineVideoCourse).subscribe(
            (res: any = []) => {
              if (res.data != null) {
                this.dialogRef.close({ data: res.data });
              }
            },
            (error) => {
              this.toaster.error(error.error.message, "An Error Occured!");
            }
          );
        }
      } else {
        if (this.firstFormGroup.valid && this.secondFormGroup.valid) {
          
          let requestObj= {
            idProduct:this.idProduct,
            topic:this.firstFormGroup.controls["topic"].value ,
            description:this.firstFormGroup.controls["description"].value ,
            idProductGroup:-1 ,
            idSyllabus: 0,
            videoDuration: 0,
            folderId: (this.secondFormGroup.controls["folder"].value).id,
            idSubjectChapter:this.firstFormGroup.controls["chapter"].value ,
            idSubject: this.firstFormGroup.controls["subject"].value,
            videoSeqNumber:this.firstFormGroup.controls["videoSeqNum"].value ,
            idClassStandard:this.firstFormGroup.controls["classStandard"].value ,
            heading:this.secondFormGroup.controls["heading"].value ,
            question:this.secondFormGroup.controls["question"].value ,
            answer: this.secondFormGroup.controls["answer"].value,
            pdfUrl:this.pdfURL,
            videoLinks: this.videoLinks,
            videoUrl:this.uploadedVideoUrl,
            videoEnLink: this.secondFormGroup.controls.videoEnLink.value,
            teacherNoteUrl: this.teacherNoteUrl
        }
  
          this.serv.createVideo(requestObj).subscribe(
            (res: any = []) => {
              if (res.data != null) {
                this.dialogRef.close({ data: res.data });
              }
            },
            (error) => {
              this.toaster.error(error.error.message, "An Error Occured!");
            }
          );
        }
      }
    }
   
  }

  getVideoStreaminginfo(): any {
    let videoId = this.secondFormGroup.controls["videoId"].value;
    this.showVideoFlag = false;
    if (videoId != null && videoId != "") {
      this.serv.getVideoStreaminginfo(videoId).subscribe(
        (res: any) => {
          if (res.data != null) {
            this.videoSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
              "https://player.vdocipher.com/playerAssets/1.x/vdo/embed/index.html#otp=" +
                res.data.otp +
                "&playbackInfo=" +
                res.data.playbackInfo
            );
            this.showVideoFlag = true;
            return true;
          }
        },
        (error) => {
          this.toaster.error(error.error.message, "An Error Occured!");
          this.secondFormGroup.controls["videoId"].setErrors({ required: true });
        }
      );
    }
  }

  getAllLanguage() {
    this.serv.getAllLanguages().subscribe((res: any = []) => {
      if (res.data != null) {
        this.languageList = res.data;
      }
    });
  }

  addVideoLink() {
    if(this.thirdFormGroup.valid){
    let lang = this.thirdFormGroup.controls["language"].value;
    let vid = this.thirdFormGroup.controls["videoId"].value;
    let head =this.thirdFormGroup.controls["heading"].value;
    let ques = this.thirdFormGroup.controls["question"].value;
    let ans = this.thirdFormGroup.controls["answer"].value;
    let pdfUrl=this.langPdfURL;
    if (lang == null || lang == "") {
      this.toaster.error("Please Select Vali Language", "Valid Data Required");
      return;
    }

    if (vid == null || vid == "") {
      this.toaster.error("Video Id Cannot Be Empty!", "Valid Data Required");
      return;
    }

    if (head == null || head == "") {
      this.toaster.error("Heading Cannot Be Empty!", "Valid Data Required");
      return;
    }

    if (ques == null || ques == "") {
      this.toaster.error("Question Cannot Be Empty!", "Valid Data Required");
      return;
    }

    if (ans == null || ans == "") {
      this.toaster.error("Answer Id Cannot Be Empty!", "Valid Data Required");
      return;
    }

    // check combinations here
    for (let i = 0; i < this.videoLinks.length; i++) {
      if (this.videoLinks[i].language === lang) {
        this.toaster.error(
          "Duplicate Language Data found!",
          "Valid Data Required"
        );
        return;
      }
    }

    this.serv.getVideoStreaminginfo(vid).subscribe(
      (res: any) => {
        if (res.data != null) {
          // this.videoSrc = this.sanitizer.bypassSecurityTrustResourceUrl("https://player.vdocipher.com/playerAssets/1.x/vdo/embed/index.html#otp="+res.data.otp+"&playbackInfo="+res.data.playbackInfo);
          // this.showVideoFlag=true;
          let object = {
            language: lang,
            link: vid,
            heading: head,
            question: ques,
            answer : ans ,
            pdfURL: this.langPdfURL
          };
          this.videoLinks.push(object);
          this.thirdFormGroup.reset();
          this.thirdFormGroup.markAsPristine();
          this.thirdFormGroup.markAsUntouched();
          this.thirdFormGroup.clearValidators();
          this.thirdFormGroup.updateValueAndValidity()

        }
      },
      (error) => {
        console.warn(error);
        this.toaster.error(error.error.message, "An Error Occured!");
        this.thirdFormGroup.controls['videoId'].setErrors({ required: true });
      }
    );
    }

    else{
      return ;
    }
  }

  removeSub(subChip, index) {
    this.videoLinks.splice(index, 1);
  }

  getProduct(idProductLine, idClassStandard, idSubject, idSyllabus, idState) {
    this.prodFlag = false;

    this.serv
      .getProductByProductLine(idProductLine, idClassStandard, idSubject, idSyllabus, idState)
      .subscribe(
        (res: any = []) => {
          if (res.data != null) {
            this.productData = res.data;
            console.log(this.productData);
            this.idProduct = res.data.idProduct;
            console.log(this.idProduct)
            this.prodFlag = true;
          }
        },
        (error) => {
          console.warn(error);
          this.toaster.error(error.error.message, "An Error Occured!");
        }
      );
  }
  setSyllabus(event){
    this.idSyllabus = event;
    this.classFlag = true;
    this.firstFormGroup.controls["classStandard"].reset();
    this.subFlag = false;
    this.playlistFalg = false;
    this.firstFormGroup.controls["subject"].reset();
    this.productData = null;
    this.prodFlag = false;
    this.firstFormGroup.controls["chapter"].reset();
    this.idProduct = null;
  }

  setState(event){
    this.idState = event;
    this.syllabusFlag = true;
    this.firstFormGroup.controls["idSyllabus"].reset();
    this.classFlag = false;
    this.firstFormGroup.controls["classStandard"].reset();
    this.subFlag = false;
    this.playlistFalg = false;
    this.firstFormGroup.controls["subject"].reset();
    this.productData = null;
    this.prodFlag = false;
    this.firstFormGroup.controls["chapter"].reset();
    this.idProduct = null;
  }
  generateStringValue()
  { 
   let heading =  this.secondFormGroup.controls["heading"].value;
   let question =  this.secondFormGroup.controls["question"].value;
   let answer =  this.secondFormGroup.controls["answer"].value;

    if ( (heading != null && heading !="") && (question != null && question !="") 
    && (answer != null && answer !="") )
    { 
      this.generatedValue =  heading +":::"
      + question +":::"
      + answer ;
      this.generatedValueFlag = true;
    }
   
  }

  insertText()
  { 
   let text = this.thirdFormGroup.controls["insertText"].value;

   if(this.insertText !=null){
     let insertValue:any= text.split(":::");
     let heading :any = insertValue[0];
     let question:any = insertValue[1];
     let answer :any = insertValue[2];  
    this.thirdFormGroup.controls["heading"].setValue(heading);
    this.thirdFormGroup.controls["question"].setValue(question);
    this.thirdFormGroup.controls["answer"].setValue(answer);
    
   }
   
  }
  onNavigateTranslator(){
    window.open("https://translate.google.co.in/", "_blank");
}
uploadVideo(videoInput:any){
  this.onVideoFileChange();
  console.log(this.secondFormGroup)
  this.uploadedVideoUrl=null;
  this.file = (videoInput.target as HTMLInputElement).files[0];
  this.formData= new FormData();
  this.formData.append("video", this.file ?? "");
  this.formData.forEach((value, key) => {
    console.log("formdata "+key, value);
  });    
  this.http.post('/api/v1/offlineCourse/upload-video', this.formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      catchError(this.errorMgmt)).subscribe((event: HttpEvent<any>) => {
        // console.log('dsadsadsadad')
        switch (event.type) {
          case HttpEventType.Sent:
            console.log('Request has been made!');
            break;
          case HttpEventType.ResponseHeader:
            console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100);
            console.log(`Uploaded! ${this.progress}%`);
            break;
          case HttpEventType.Response:
            console.log('Video Uploaded Successfully!', event.body);
            if (event.body.statusCode === 201 || event.body.statusCode === 200) {
              this.toaster.success("video Uploaded Successfully!", "Uploaded");
              this.uploadedVideoUrl= event.body.data;
              // window.location.reload();
              // console.log("video", this.uploadedVideoUrl, event.body)
            }
            else {
              this.toaster.error(event.body.message, "Error");
            }
          // setTimeout(() => {
          //   this.progress = 0;
          // }, 1500);
  
        }
      })
}
uploadTeacherNote(fileInput:any){
  const file = (fileInput.target as HTMLInputElement).files[0];
    console.log(file);
    var formData: any = new FormData();
    formData.append("teacherNote", file);

    this.http.post('/api/v1/offlineCourse/upload-teacher-note', formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      catchError(this.errorMgmt)).subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            console.log('Request has been made!');
            break;
          case HttpEventType.ResponseHeader:
            console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100);
            console.log(`Uploaded! ${this.progress}%`);
            break;
          case HttpEventType.Response:
            console.log('Pdf Uploaded Successfully!', event.body);
            if (event.body.statusCode === 201 || event.body.statusCode === 200) {
              this.toaster.success("Pdf Uploaded Successfully!", "Uploaded");
              this.teacherNoteUrl= event.body.data;
              this.checkTeacherNote()
              console.log("teacherNoteUrl",this.teacherNoteUrl);
              // window.location.reload();
            }
            else {
              this.toaster.error(event.body.message, "Error");
            }
          // setTimeout(() => {
          //   this.progress = 0;
          // }, 1500);
  
        }
      })
   
}

uploadPdf(fileInput:any){

  this.pdfURL=null;
  const file = (fileInput.target as HTMLInputElement).files[0];
    console.log(file);
    var formData: any = new FormData();
    formData.append("liveClassPdf", file);
    console.log("formdata",formData);
    this.http.post('/api/v1/liveClass/uploadPdf', formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      catchError(this.errorMgmt)).subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            console.log('Request has been made!');
            break;
          case HttpEventType.ResponseHeader:
            console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100);
            console.log(`Uploaded! ${this.progress}%`);
            break;
          case HttpEventType.Response:
            console.log('Pdf Uploaded Successfully!', event.body);
            if (event.body.statusCode === 201 || event.body.statusCode === 200) {
              this.toaster.success("Pdf Uploaded Successfully!", "Uploaded");
              this.pdfURL= event.body.data;
              console.log("pdf",this.pdfURL);
              // window.location.reload();
            }
            else {
              this.toaster.error(event.body.message, "Error");
            }
          // setTimeout(() => {
          //   this.progress = 0;
          // }, 1500);
  
        }
      })
      
}


uploadLanguagePdf(fileInput:any){

  this.langPdfURL=null;
  const file = (fileInput.target as HTMLInputElement).files[0];
    console.log(file);
    var formData: any = new FormData();
    formData.append("liveClassPdf", file);
    console.log("formdata",formData);
    this.http.post('/api/v1/liveClass/uploadPdf', formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      catchError(this.errorMgmt)).subscribe((event: HttpEvent<any>) => {
        
        switch (event.type) {
          case HttpEventType.Sent:
            console.log('Request has been made!');
            break;
          case HttpEventType.ResponseHeader:
            console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100);
            console.log(`Uploaded! ${this.progress}%`);
            break;
          case HttpEventType.Response:
            console.log('Pdf Uploaded Successfully!', event.body);
            if (event.body.statusCode === 201 || event.body.statusCode === 200) {
              this.toaster.success("Pdf Uploaded Successfully!", "Uploaded");
              this.langPdfURL= event.body.data;
              console.log("Laanguage pdf",this.langPdfURL);
              // window.location.reload();
            }
            else {
              this.toaster.error(event.body.message, "Error");
            }
          // setTimeout(() => {
          //   this.progress = 0;
          // }, 1500);
  
        }
      })
      
}
errorMgmt(error: HttpErrorResponse) {
  let errorMessage = '';
  if (error.error instanceof ErrorEvent) {
    // Get client-side error
    errorMessage = error.error.message;
  } else {
    // Get server-side error
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  }
  console.log(errorMessage);
  return throwError(errorMessage);
}

removepdf() {
  this.pdfURL = null;
  this.secondFormGroup.controls["pdfURL"].setValue("");
  this.secondFormGroup.controls["pdfURL"].reset();
}
removeTeacherNote() {
    let path = new URL(this.teacherNoteUrl).pathname;
    this.secondFormGroup.controls["teacherNoteUrl"].setValue(null);
    this.teacherNoteUrl= null;
    this.secondFormGroup.controls['teacherNoteUrl'].reset();
}
removeVideo(){
  console.log(this.secondFormGroup.controls)
  this.secondFormGroup.controls["videoUrl"].setValue("");
  this.uploadedVideoUrl = null;
  this.secondFormGroup.controls["videoUrl"].reset();
  let path = new URL(this.uploadedVideoUrl).pathname;
let extractedPath = path.match(/vdocipher\/.*/)[0];  // Regex to match "vdocipher" and everything after
console.log(extractedPath); 
  this.http.delete ('/api/v1/offlineCourse/delete-video?key='+extractedPath).subscribe(
    (res:any)=>{
      console.log('Response', res)
    }
  )
  console.log('dsadad', this.secondFormGroup.controls["videoUrl"])
}
getHeadings(idChapter)
{
  if ( this.idProduct == null)
  {
    this.toaster.warning("no product selected");
    return null;
  }
  this.headings  = [];
  this.http.get("/api/v1/offlineCourse/headings?idProduct="+this.idProduct+"&idSubjectChapter="+idChapter).subscribe(
    (res: any) => {
     this.headings = res.data;
     console.log(this.headings); 
    },
    (err: any) => {
      this.headings = [];
    }
  );

}

displayFn(heading: Headings): string {
  return heading && heading.heading ? heading.heading : '';
}
private _filter(name: string): Headings[] {
  const filterValue = name.toLowerCase();

  return this.headings.filter(option => option.heading.toLowerCase().includes(filterValue));
}

}


export interface Headings 
{
  heading:string;
}